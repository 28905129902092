import React, { useState, useContext, useRef, useEffect } from 'react'
import logo from './logo.svg';
import darkLogo from './darkLogo.svg';
import './App.css';
import ThemeContext from "./theme/ThemeContext";

import { Amplify } from 'aws-amplify';
import { Storage } from 'aws-amplify';

import { useAuth0 } from '@auth0/auth0-react';

import { DownloadTableExcel } from 'react-export-table-to-excel';
import { on } from 'web-bluetooth-dfu';

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-2:ee758732-8c21-48af-866e-8d4a670f901a', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-east-2', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: 'sp-mobileapps173146-staging', //REQUIRED -  Amazon S3 bucket name
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
    }
  }
});

function App() {
  const [connected, setConnected] = useState(0)
  const [DCMconnected, setDCMConnected] = useState('')
  const [errorHappened, setErrorHappened] = useState(0)
  const [errorName, setErrorName] = useState('')

  const [errorNameData, setErrorNameData] = useState('')
  const [errorNameDebug, setErrorNameDebug] = useState('')

  const [updating, setUpdating] = useState(0)
  const [reading, setReading] = useState(0)
  const [readingDebug, setReadingDebug] = useState(0)
  const [gettingVersion, setGettingVersion] = useState(0)
  const [gettingOBD, setGettingOBD] = useState(0)
  const [cooldown, setCooldown] = useState(0)

  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();

  const [folderIsReady, setFolderIsReady] = useState(false)
  const [file, setFile] = useState('')
  const [dropdown, setDropdown] = useState([{ key: 0 }])

  var [currentColumnsID, setCurrentColumnsID] = useState('');
  /* Calls get getColumnNames to get the name of columns in data read as soon as it knows the customer and the version */
  useEffect(() => {
    //console.log(currentColumnsID)
    getColumnNames(currentColumnsID);

  }, [currentColumnsID]);
  var [currentColumnsNames, setCurrentColumnsNames] = useState('');


  var [render, setRender] = useState(true);
  var [renderDebug, setRenderDebug] = useState(true);
  var [read, setRead] = useState('START READ');
  var [readDebug, setReadDebug] = useState('START DEBUG');
  var [currentlyReadingDCM, setCurrentlyReadingDCM] = useState("SPDCM");
  var [currentlyReadingDCMVersion, setCurrentlyReadingDCMVersion] = useState("");
  var [currentlyReadingDCMDebug, setCurrentlyReadingDCMDebug] = useState("SPDCM");
  var [tableData, setTableData] = useState([]);
  var [numberOfRows, setNumberOfRows] = useState(0);
  /* Draws the checkboxes when the number of columns is decided when is reading data */
  useEffect(() => {
    var temp = activeRows;
    for (let index = 0; index < numberOfRows; index++) {
      temp.push(1);
    }
    setActiveRows(temp)
    var html = '';
    if (numberOfRows > 0) {
      html = html + '<label>VISIBLE COLUMNS: </label>';
    }
    for (let index = 0; index < temp.length; index++) {
      html = html + '<label><input type="checkbox" id="' + (index + 1) + '"';
      if (activeRows[index] === 1) {
        html = html + ' checked '
      }
      html = html + '/>' + (index + 1) + '</label>';

      setRowSelectionHtml(html)
    }
    if (renderTable === true) {
      if (numberOfRows > 0) {
        html = html + '<label><input type="checkbox" id="0" checked/>ALL</label>'
      }
      setRowSelectionHtml(html)
      document.getElementById("rowSelection").innerHTML = html;
      if (numberOfRows > 0) {
        const someCheckbox = document.getElementById("0");
        const tempRows = activeRows;
        someCheckbox.addEventListener('change', e => {
          if (e.target.checked === true) {
            for (let index = 0; index < temp.length; index++) {
              tempRows[index] = 1;
            }
            setActiveRows(tempRows);
            switchAllChecks(1)
          }
          if (e.target.checked === false) {
            for (let index = 0; index < temp.length; index++) {
              tempRows[index] = 0;
            }
            setActiveRows(tempRows);
            switchAllChecks(0)
          }
        });
      }
      for (let index = 0; index < temp.length; index++) {
        const someCheckbox = document.getElementById((index + 1).toString());
        const tempRows = activeRows;
        someCheckbox.addEventListener('change', e => {
          if (e.target.checked === true) {
            tempRows[index] = 1;
            setActiveRows(tempRows);
            let AllActive = false
            for (let index = 0; index < tempRows.length; index++) {
              if (tempRows[index] === 1) {
                AllActive = true;
              }
            }
            document.getElementById("0").checked = AllActive;
            drawTable()
          }
          if (e.target.checked === false) {
            tempRows[index] = 0;
            setActiveRows(tempRows);
            let AllActive = false
            for (let index = 0; index < tempRows.length; index++) {
              if (tempRows[index] === 1) {
                AllActive = true;
              }
            }
            document.getElementById("0").checked = AllActive;
            drawTable()
          }
        });
      }
    }

  }, [numberOfRows]);
  var [activeRows, setActiveRows] = useState([]);

  var [tableDataDebug, setTableDataDebug] = useState([]);
  var [renderTable, setRenderTable] = useState(false);
  var [renderTableDebug, setRenderTableDebug] = useState(false);
  var [renderFileSelection, setRenderFileSelection] = useState(false);
  var [renderManualWindow, setRenderManualWindow] = useState(false);
  var [renderTutorials, setRenderTutorials] = useState(false);
  var [tableHtml, setTableHtml] = useState("<div></div>");
  var [rowSelectionHtml, setRowSelectionHtml] = useState("<div></div>");
  var [tableHtmlDebug, setTableHtmlDebug] = useState("<div></div>");
  var [excelReady, setExcelReady] = useState(false);
  var [excelReadyDebug, setExcelReadyDebug] = useState(false);
  var [autoRead, setAutoRead] = useState(true);
  var [autoReadDebug, setAutoReadDebug] = useState(true);
  var [closeAble, setCloseAble] = useState(true);
  var [closeAbleDebug, setCloseAbleDebug] = useState(true);

  const [esp32DeviceHook, setEsp32DeviceHook] = useState('')
  const [esp32DeviceHookDebug, setEsp32DeviceHookDebug] = useState('')
  const [isEngineOn, setIsEngineOn] = useState('')
  const [isMoving, setIsMoving] = useState('')
  const [isParked, setIsParked] = useState('')

  var [queue, setQueue] = useState(1);
  var [errorQueue, setErrorQueue] = useState(1);
  /* When the DCM queue is empty, sets the Updating flag to 0, enabling other functions */
  useEffect(() => {
    if (dcmQueue.slice(0).length === 1) {
      setUpdating(0);
    }
  }, [queue]);
  /* When there is an error during an update, sends the DCM to the end of the queue */
  useEffect(() => {
    if (errorQueue > 1) {
      setErrorHappened(0);
      var temp = dcmQueue.slice(0);
      temp.push(dcmQueue[0]);
      setDcmQueue(temp);
      var dq = deviceQueue.slice(0);
      dq.push(deviceQueue[0])
      setDeviceQueue(dq);
      setQueue(queue => queue + 1);
    }
  }, [errorQueue]);
  var [errorCount, setErrorCount] = useState(0);

  var [deviceQueue, setDeviceQueue] = useState([]);
  /** When a device is updated, it refreshes the queue moving the next DCM forward */
  useEffect(() => {
    if (deviceQueue.slice(0).length === 1) {
      setDcmQueue(dcmQueue.slice(1))
      setDeviceQueue([])
      setUpdating(0);
      setErrorCount(0);
    }
    if (deviceQueue.slice(0).length > 1) {
      var dq = deviceQueue.slice(1);
      setDeviceQueue(dq);
      setDcmQueue(dcmQueue.slice(1))
      connectDCM(dq[0]);
    }
  }, [queue]);

  /**If the DCM queue is not empty, sets the Updating flag to 1, blocking other functions */
  useEffect(() => {
    if (deviceQueue.slice(0).length === 1 && updating === 0) {
      setUpdating(1);
      connectDCM(deviceQueue[0])
    }
  }, [deviceQueue]);

  var [dcmQueue, setDcmQueue] = useState([]);
  /**Controls the DCM queue displayed on the screen */
  useEffect(() => {
    let list = document.getElementById('list');
    let data = dcmQueue.slice(1)
    if (dcmQueue.slice(0).length > 0) {
      list.innerHTML = "";
    }
    if (data.length > 0) {
      list.innerHTML = data.map(i => `<li>${i}</li>`).join('');
    }
  }, [dcmQueue]);
  var [excluded, setExcluded] = useState();
  /**Creates a list with the names of the DCMs already selected for updates and uses it to prevent the user from select them again */
  useEffect(() => {
    setExcluded([{ name: "404" }]);
    for (let index = 0; index < dcmQueue.length; index++) {
      excluded.push({ name: dcmQueue[index] });
      setExcluded(excluded);
    }
  }, [dcmQueue]);

  const { darkmode } = useContext(ThemeContext);

  const [itemLogs, setItemLogs] = useState(getLogs());
  /**Gets the logs when the website is refreshed */
  useEffect(() => {
    getLogs().then(list => setItemLogs(list));
  }, []);

  /** References to tables, so they can be exported as Excels */
  const tableRef = useRef(null);
  const tableRefDebug = useRef(null);

  /**UUIDs */
  var UUID_FOTA_SERVICE = "53880000-65fd-4651-ba8e-91527f06c887"
  var UUID_BINARY_STREAM_CHAR = "53880001-65fd-4651-ba8e-91527f06c887"
  var UUID_CONTROL_CHAR = "53880002-65fd-4651-ba8e-91527f06c887"
  var UUID_STATUS_CHAR = "53880003-65fd-4651-ba8e-91527f06c887"
  var UUID_FIRMWARE_REVISION_STRING_CHAR = "00002a26-0000-1000-8000-00805f9b34fb"
  var DataHeader_SERVICE_UUID = "8b443003-32f7-410b-b5ab-5c2781f23124"
  var DataHeader_STATUS_CHAR_UUID = "8b443004-32f7-410b-b5ab-5c2781f23124"
  var Debug_SERVICE_UUID = "8b443000-32f7-410b-b5ab-5c2781f23124"
  var Debug_PARKED_CHAR_UUID = "8b443001-32f7-410b-b5ab-5c2781f23124"
  var Debug_MOVING_CHAR_UUID = "8b443002-32f7-410b-b5ab-5c2781f23124"
  var Debug_ENGINE_CHAR_UUID = "8b443003-32f7-410b-b5ab-5c2781f23124"
  var Data_STATUS_CHAR_UUID = "8b443004-32f7-410b-b5ab-5c2781f23124"
  var FOTA_OP_CODE_START = new Uint8Array([0x01])
  var FOTA_OP_CODE_COMMIT = new Uint8Array([0x03])

  // Number of bytes in a single BSC packet, excluding fragment ID
  var FRAGMENT_SIZE = 128

  // Max. Frag. ID before overflow to zero
  var MAXIMUM_FRAGMENT_ID = 255

  let esp32Device = null
  let OBD = ""
  let esp32Service = null;
  let dataReadingServer = null;
  let updateData = null;
  let fragment_id = 0
  let rollover_counter = 0

  var totalSize;

  const timer = ms => new Promise(res => setTimeout(res, ms))

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isAuthenticated && folderIsReady === false) {
    setFolderIsReady(true);
    //
    //
    // HERE IS WHERE IT GETS THE NAME OF THE COMPANY
    //
    //
    getOptions(((user.name.slice(user.name.indexOf("@") + 1)).slice(0, -4)));
  }
  /*Access the JSON from AWS with the Logs
  Returns a text in json format*/
  async function getLogs() {
    try {
      const result = await Storage.get("DCMupdates/Logs.json");
      const response = await fetch(result, {
        headers: {
          'Cache-Control': 'no-cache, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 0
        }
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.log("Error loading file: ", error);
    }
  }

  /* In the read data window, hides or shows all columns  and unchecks or checks all checkboxes 
  The parameter i is a 1 if it needs to check all of the checkboxes and 0 if it needs to uncheck all of them*/
  function switchAllChecks(i) {
    drawTable()
    var temp = activeRows;
    var html = '';
    if (numberOfRows > 0) {
      html = html + '<label>VISIBLE COLUMNS: </label>';
    }
    for (let index = 0; index < temp.length; index++) {
      html = html + '<label><input type="checkbox" id="' + (index + 1) + '"';
      if (activeRows[index] === 1 || i === 1) {
        html = html + ' checked '
      }
      html = html + '/>' + (index + 1) + '</label>';

      setRowSelectionHtml(html)
    }
    if (numberOfRows > 0) {
      if (i === 1) {
        html = html + '<label><input type="checkbox" id="0" checked/>ALL</label>'
      } else {
        html = html + '<label><input type="checkbox" id="0"/>ALL</label>'
      }
    }
    setRowSelectionHtml(html)
    document.getElementById("rowSelection").innerHTML = html;
    if (numberOfRows > 0) {
      const someCheckbox = document.getElementById("0");
      const tempRows = activeRows;
      someCheckbox.addEventListener('change', e => {
        if (e.target.checked === true) {
          for (let index = 0; index < temp.length; index++) {
            tempRows[index] = 1;
          }
          setActiveRows(tempRows);
          switchAllChecks(1)
        }
        if (e.target.checked === false) {
          for (let index = 0; index < temp.length; index++) {
            tempRows[index] = 0;
          }
          setActiveRows(tempRows);
          switchAllChecks(0)
        }
      });

      for (let index = 0; index < temp.length; index++) {
        const someCheckbox = document.getElementById((index + 1).toString());
        const tempRows = activeRows;
        someCheckbox.addEventListener('change', e => {
          if (e.target.checked === true) {
            tempRows[index] = 1;
            setActiveRows(tempRows);
            let AllActive = false
            for (let index = 0; index < tempRows.length; index++) {
              if (tempRows[index] === 1) {
                AllActive = true;
              }
            }
            document.getElementById("0").checked = AllActive;
            drawTable()
          }
          if (e.target.checked === false) {
            tempRows[index] = 0;
            setActiveRows(tempRows);
            let AllActive = false
            for (let index = 0; index < tempRows.length; index++) {
              if (tempRows[index] === 1) {
                AllActive = true;
              }
            }
            document.getElementById("0").checked = AllActive;
            drawTable()
          }
        });
      }
    }
  }

  /* Checks if the latest 2 pushes to an array are the same so when reading data it doesn't show two lines in a row with the same data 
  Receives an array with the data read
  Returns false if they are the same and true if they are not*/
  function removeDuplicates(array) {
    if (array.length > 1) {
      let array1 = JSON.stringify(array[array.length - 1]);
      let array2 = JSON.stringify(array[array.length - 2]);

      array1 = array1.substring(array1.indexOf(",") + 1);
      array2 = array2.substring(array2.indexOf(",") + 1);

      if (array1 === array2) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /* Adds a log entry 
  The parameter is a string that describes one action made by the user */
  async function addLog(action) {
    var jsonFile = itemLogs;
    let newDate = new Date();
    jsonFile.push({ "Log": (newDate.getMonth() + 1) + "/" + newDate.getDate() + "/" + newDate.getFullYear() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds(), "User": user.name, "Action": action });
    setItemLogs(jsonFile)
    try {
      await Storage.put("DCMupdates/Logs.json", jsonFile);
    } catch (error) {
      console.log("Error uploading logs: ", error);
    }
  }


  /* Shows or hides the read data window 
  Making sure that the columns are collapsible takes most of the code*/
  async function switchWindow() {
    setRenderTable(!renderTable);
    if (render === false) {
      document.getElementById("displayTable").innerHTML = tableHtml;
      document.getElementById("rowSelection").innerHTML = rowSelectionHtml;

    }
    if (renderTable === false && tableData.length > 0) {
      setExcelReady(false)
      await timer(100);
      document.getElementById("displayTable").innerHTML = tableHtml;
      //document.getElementById("rowSelection").innerHTML = rowSelectionHtml;
      await timer(100);
      setExcelReady(true)

      var temp = activeRows;
      setActiveRows(temp)
      var html = '';
      if (numberOfRows > 0) {
        html = html + '<label>VISIBLE COLUMNS: </label>';
      }
      for (let index = 0; index < temp.length; index++) {
        html = html + '<label><input type="checkbox" id="' + (index + 1) + '"';
        if (activeRows[index] === 1) {
          html = html + ' checked '
        }
        html = html + '/>' + (index + 1) + '</label>';

        setRowSelectionHtml(html)
        //document.getElementById("rowSelection").innerHTML = html;
      }
      if (numberOfRows > 0) {
        const tempRows = activeRows;
        let htmlAll = '<label><input type="checkbox" id="0"/>ALL</label>'
        for (let index = 0; index < temp.length; index++) {
          if (tempRows[index] === 1) {
            htmlAll = '<label><input type="checkbox" id="0" checked/>ALL</label>'
          }
        }
        html = html + htmlAll
      }
      setRowSelectionHtml(html)
      document.getElementById("rowSelection").innerHTML = html;
      if (numberOfRows > 0) {
        const someCheckbox = document.getElementById("0");
        const tempRows = activeRows;
        someCheckbox.addEventListener('change', e => {
          if (e.target.checked === true) {
            for (let index = 0; index < temp.length; index++) {
              tempRows[index] = 1;
            }
            setActiveRows(tempRows);
            switchAllChecks(1)
          }
          if (e.target.checked === false) {
            for (let index = 0; index < temp.length; index++) {
              tempRows[index] = 0;
            }
            setActiveRows(tempRows);
            switchAllChecks(0)
          }
        });
      }

      for (let index = 0; index < temp.length; index++) {
        const someCheckbox = document.getElementById((index + 1).toString());
        const tempRows = activeRows;
        someCheckbox.addEventListener('change', e => {
          if (e.target.checked === true) {
            tempRows[index] = 1;
            setActiveRows(tempRows);
            let AllActive = false
            for (let index = 0; index < tempRows.length; index++) {
              if (tempRows[index] === 1) {
                AllActive = true;
              }
            }
            document.getElementById("0").checked = AllActive;
            drawTable()
          }
          if (e.target.checked === false) {
            tempRows[index] = 0;
            setActiveRows(tempRows);
            let AllActive = false
            for (let index = 0; index < tempRows.length; index++) {
              if (tempRows[index] === 1) {
                AllActive = true;
              }
            }
            document.getElementById("0").checked = AllActive;
            drawTable()
          }
        });

      }
    }
  }

  /* Shows or hides the debug DCM window */
  async function switchWindowDebug() {
    setRenderTableDebug(!renderTableDebug);
    if (renderDebug === false) {
      document.getElementById("displayTableDebug").innerHTML = tableHtmlDebug;
    }
    if (renderTableDebug === false && tableDataDebug.length > 0) {
      setExcelReadyDebug(false)
      await timer(100);
      document.getElementById("displayTableDebug").innerHTML = tableHtmlDebug;
      await timer(100);
      setExcelReadyDebug(true)
    }
  }

  /* Shows or hides the window with the manual*/
  async function switchManualWindow() {
    setRenderManualWindow(!renderManualWindow);
    if (!renderManualWindow) {
      getManual()
    }
  }

  /* Shows or hides the window with the tutorial videos */
  async function switchTutorials() {
    setRenderTutorials(!renderTutorials);
    if (!renderTutorials) {
      getTutorials()
    }
  }

  /* Clear the read data table */
  async function clearTable() {
    setNumberOfRows(0)
    setActiveRows([])
    setRowSelectionHtml("")
    setTableData([]);
    setNumberOfRows(0);
    setCurrentColumnsID('');
    setCurrentColumnsNames('');
    setCurrentlyReadingDCM("SPDCM");
    document.getElementById("displayTable").innerHTML = "";
    document.getElementById("rowSelection").innerHTML = "";
  }

  /* Clear the debug DCM output */
  async function clearTableDebug() {
    setTableDataDebug([]);
    setCurrentlyReadingDCMDebug("SPDCM");
    document.getElementById("displayTableDebug").innerHTML = "";
  }

  /* Starts or stops reading data */
  async function readerSwitch() {
    setErrorNameData("");
    for (var i = 0; i < 1000000; i++) {
      clearInterval(i);
    }
    try {
      let intervalId = 0;
      if (render === true) {
        await timer(1000);
        navigator.bluetooth.requestDevice({
          filters: [
            {
              namePrefix: currentlyReadingDCM,
            },
          ],
          optionalServices: [UUID_FOTA_SERVICE, DataHeader_SERVICE_UUID, Debug_SERVICE_UUID]
        })
          .then(device => {
            setCloseAble(false)
            setExcelReady(false)
            setRender(!render);
            setReading(1);
            setRenderTable(true);
            setRead('STOP READ');
            setEsp32DeviceHook(device)
            setCurrentlyReadingDCM(device.name)
            return device.gatt.connect()
          })
          .then(server => {
            dataReadingServer = server;
            return server.getPrimaryService(Debug_SERVICE_UUID)
          })
          .then(service => {
            esp32Service = service;
            addLog("Started reading data from" + esp32Service.device.name);
            intervalId = setInterval(async () => {
              let newDate = new Date();
              let row = await service.getCharacteristic(Data_STATUS_CHAR_UUID);
              let version = ""
              let value = await row.readValue()
              let AB = new Uint8Array(value.buffer)
              for (var i = 0; i < value.buffer.byteLength; i++) {
                version = version + String.fromCharCode(AB[i])
              }
              let temp = tableData;
              version = newDate.getHours() + ":" + newDate.getMinutes() + ":" + ("0" + newDate.getSeconds()).slice(-2) + ":" + newDate.getMilliseconds() + ";" + version
              const FinalDataArray = version.split(";");
              if (numberOfRows === 0) {
                setNumberOfRows(FinalDataArray.length);
              }
              temp.push(FinalDataArray)
              if (!removeDuplicates(temp)) {
                temp.pop()
              }
              setTableData(temp);
              drawTable()
            }, 1000)
          })
          .then(_ => dataReadingServer.getPrimaryService(Debug_SERVICE_UUID))
          .then(service => service.getCharacteristic(Debug_PARKED_CHAR_UUID))
          .then(characteristic => characteristic.startNotifications())
          .then(characteristic => {
            characteristic.addEventListener('characteristicvaluechanged', handleParkedChanged);
          })
          .then(_ => dataReadingServer.getPrimaryService(Debug_SERVICE_UUID))
          .then(service => service.getCharacteristic(Debug_MOVING_CHAR_UUID))
          .then(characteristic => characteristic.startNotifications())
          .then(characteristic => {
            characteristic.addEventListener('characteristicvaluechanged', handleMovingChanged);
          })
          .then(_ => dataReadingServer.getPrimaryService(Debug_SERVICE_UUID))
          .then(service => service.getCharacteristic(Debug_ENGINE_CHAR_UUID))
          .then(characteristic => characteristic.startNotifications())
          .then(characteristic => {
            characteristic.addEventListener('characteristicvaluechanged', handleEngineChanged);
          })
          .then(_ => {
            return dataReadingServer.getPrimaryService(Debug_SERVICE_UUID)
          })
          .then(service => {
            esp32Service = service;
            return esp32Service.getCharacteristic(Debug_PARKED_CHAR_UUID)
          })
          .then(characteristic => {
            return characteristic.readValue()
          })
          .then(ver => {
            let result = new Uint8Array(ver.buffer)
            setIsParked(result[0])
          })
          .then(_ => {
            return esp32Service.getCharacteristic(Debug_MOVING_CHAR_UUID)
          })
          .then(characteristic => {
            return characteristic.readValue()
          })
          .then(ver => {
            let result = new Uint8Array(ver.buffer)
            setIsMoving(result[0])
          })
          .then(_ => {
            return esp32Service.getCharacteristic(Debug_ENGINE_CHAR_UUID)
          })
          .then(characteristic => {
            return characteristic.readValue()
          })
          .then(ver => {
            let result = new Uint8Array(ver.buffer)
            setIsEngineOn(result[0])
          })
          .then(_ => {
            return dataReadingServer.getPrimaryService(UUID_FOTA_SERVICE)
          })
          .then(service => {
            return service.getCharacteristic(UUID_FIRMWARE_REVISION_STRING_CHAR)
          })
          .then(characteristic => {
            return characteristic.readValue()
          }
          )
          .then(ver => {
            let version = ""
            let AB = new Uint8Array(ver.buffer)
            for (var i = 0; i < ver.buffer.byteLength; i++) {
              version = version + String.fromCharCode(AB[i])
            }
            setCurrentlyReadingDCMVersion(version)
          })
          .catch(error => {
            console.error(error);
            setErrorNameData(error.toString());
          });
      }
      else {
        setAutoRead(false)
        await timer(1000);
        await esp32DeviceHook.gatt.disconnect()
        addLog("Stopped reading data");
        await timer(1000);
        setReading(0);
        setRender(!render);
        setRead('START READ');
        setExcelReady(true)
        setAutoRead(true)
        setCloseAble(true)
      }
    } catch (error) {
      setRender(true);
      setRenderTable(false);
      console.log(error);
      setRead('START READ');
      setErrorHappened(1);
      setErrorNameData("Something went wrong with the data read");
    }
  }

  /* While reading data, keeps an eye on OBD data and changes the value of Parked if necessary 
  The parameter event, is a change in the value that is observing*/
  function handleParkedChanged(event) {
    const value = event.target.value;
    const result = new Uint8Array(value.buffer)
    setIsParked(result[0]);
  }

  /* While reading data, keeps an eye on OBD data and changes the value of Moving if necessary 
  The parameter event, is a change in the value that is observing*/
  function handleMovingChanged(event) {
    const value = event.target.value;
    const result = new Uint8Array(value.buffer)
    setIsMoving(result[0]);
  }

  /* While reading data, keeps an eye on OBD data and changes the value of Engine if necessary 
  The parameter event, is a change in the value that is observing*/
  function handleEngineChanged(event) {
    const value = event.target.value;
    const result = new Uint8Array(value.buffer)
    setIsEngineOn(result[0]);
  }

  //
  //THIS FUNCTION RIGHT HERE IS THEORETICALLY CAPABLE OF KEEPING UP WITH THE DCM DEBUG(SWOVIEW) IF WE IMPLEMENT IT SOMEDAY
  //ALL IT NEEDS TO WORK IS REPLACING THE UUID WITH THE ONES THAT CORRESPOND TO THE NEW DEBUG SERVICE
  //ALSO, THE BUTTON THAT TRIGGERS THIS NEED TO BE ENABLED, YOU SHOULD BE ABLE TO FIND IT IN THE LINES 1766-1781 
  //EACH TIME IT EXPECTS TO FIND A STRING FROM THE DCM
  //
  async function readerSwitchDebug() {
    setErrorNameDebug("");
    for (var i = 0; i < 1000000; i++) {
      clearInterval(i);
    }
    try {
      let intervalId = 0;
      if (renderDebug === true) {
        await timer(1000);
        navigator.bluetooth.requestDevice({
          filters: [
            {
              namePrefix: currentlyReadingDCMDebug,
            },
          ],
          //REPLACE Data_SERVICE_UUID DOWN HERE
          optionalServices: [UUID_FOTA_SERVICE, Debug_SERVICE_UUID]
        })
          .then(device => {
            setCloseAbleDebug(false)
            setExcelReadyDebug(false)
            setRenderDebug(!renderDebug);
            setReadingDebug(1);
            setRenderTableDebug(true);
            setReadDebug('STOP DEBUG');
            setEsp32DeviceHookDebug(device)
            setCurrentlyReadingDCMDebug(device.name)
            return device.gatt.connect()
          })
          .then(server => {
            dataReadingServer = server;
            //CHANGE THE SERVICE UUID DOWN HERE
            return server.getPrimaryService(Debug_SERVICE_UUID)
          })              //CHANGE THE CHARACTERISTIC UUID DOWN HERE
          .then(service => service.getCharacteristic(Data_STATUS_CHAR_UUID))
          .then(characteristic => characteristic.startNotifications())
          .then(characteristic => {
            characteristic.addEventListener('characteristicvaluechanged',
              handleDebugValueChanged);
          })
          .catch(error => {
            console.error(error);
            setErrorNameDebug(error.toString());
          });
      }
      else {
        setAutoReadDebug(false)
        await timer(1000);
        await esp32DeviceHookDebug.gatt.disconnect()
        addLog("Stopped debuging");
        await timer(1000);
        setReadingDebug(0);
        setRenderDebug(!renderDebug);
        setReadDebug('START DEBUG');
        setExcelReadyDebug(true)
        setAutoReadDebug(true)
        setCloseAbleDebug(true)
      }
    } catch (error) {
      setRenderDebug(true);
      setRenderTableDebug(false);
      console.log(error);
      setReadDebug('START DEBUG');
      setErrorHappened(1);
      setErrorNameDebug('Something went wrong with the debug');
    }
  }

  /* Takes the rows obtained for DCM debug and adds them to the table checking that is not getting the same data twice in a row 
  The parameter event, is a change in the value that is observing*/
  function handleDebugValueChanged(event) {
    let version = ""
    let newDate = new Date();
    const value = event.target.value;
    let AB = new Uint8Array(value.buffer)
    for (var i = 0; i < value.buffer.byteLength; i++) {
      version = version + String.fromCharCode(AB[i])
    }
    version = newDate.getHours() + ":" + newDate.getMinutes() + ":" + ("0" + newDate.getSeconds()).slice(-2) + ":" + newDate.getMilliseconds() + "  &ensp;  " + version

    let temp = tableDataDebug;
    const FinalDataArray = [];
    FinalDataArray.push(version);
    temp.push(FinalDataArray)
    if (!removeDuplicates(temp)) {
      temp.pop()
    }
    setTableDataDebug(temp);
    drawTableDebug()

  }

  /* Gets the DCM firmware version */
  async function getVersion() {
    navigator.bluetooth.requestDevice({
      filters: [
        {
          namePrefix: "SPDCM",
        },
      ],
      optionalServices: [UUID_FOTA_SERVICE]
    })
      .then(device => {
        setGettingVersion(1);
        esp32Device = device
        return device.gatt.connect()
      })
      .then(server => {
        return server.getPrimaryService(UUID_FOTA_SERVICE)
      })
      .then(service => {
        esp32Service = service;
        addLog("Tried to check the firmware version of " + esp32Service.device.name);
        return esp32Service.getCharacteristic(UUID_FIRMWARE_REVISION_STRING_CHAR)
      })
      .then(characteristic => {
        //console.log(characteristic)
        return characteristic.readValue()
      }
      )
      .then(ver => {
        let version = ""
        let AB = new Uint8Array(ver.buffer)
        for (var i = 0; i < ver.buffer.byteLength; i++) {
          version = version + String.fromCharCode(AB[i])
        }
        setConnected(1);
        setDCMConnected(esp32Service.device.name + " has the version " + version)
        addLog("Checked the firmware version of " + esp32Service.device.name + " and it was " + version);
      }).then(_ => {
        esp32Device.gatt.disconnect()
        setGettingVersion(0)
      }
      )
  }

  /* Gets the OBD data and displays it in the main window */
  async function getObdData() {
    navigator.bluetooth.requestDevice({
      filters: [
        {
          namePrefix: "SPDCM",
        },
      ],
      optionalServices: [Debug_SERVICE_UUID]
    })
      .then(device => {
        setGettingOBD(1);
        esp32Device = device
        return device.gatt.connect()
      })
      .then(server => {
        return server.getPrimaryService(Debug_SERVICE_UUID)
      })
      .then(service => {
        esp32Service = service;
        addLog("Tried to get OBD data from " + esp32Service.device.name);
        return esp32Service.getCharacteristic(Debug_PARKED_CHAR_UUID)
      })
      .then(characteristic => {
        return characteristic.readValue()
      })
      .then(ver => {
        let result = new Uint8Array(ver.buffer)
        OBD = "Is parked: " + result[0];
      })
      .then(_ => {
        return esp32Service.getCharacteristic(Debug_MOVING_CHAR_UUID)
      })
      .then(characteristic => {
        return characteristic.readValue()
      })
      .then(ver => {
        let result = new Uint8Array(ver.buffer)
        OBD = OBD + " Is moving: " + result[0]
      })
      .then(_ => {
        return esp32Service.getCharacteristic(Debug_ENGINE_CHAR_UUID)
      })
      .then(characteristic => {
        return characteristic.readValue()
      })
      .then(ver => {
        let result = new Uint8Array(ver.buffer)
        setConnected(1);
        OBD = OBD + " Is Engine On: " + result[0] + " (" + esp32Service.device.name + ")"
        setDCMConnected(OBD)
        addLog("Checked the OBD data from " + esp32Service.device.name);
      })
      .then(_ => {
        esp32Device.gatt.disconnect()
        setGettingOBD(0)
      })
      .catch(error => {
        console.error(error);
        setDCMConnected(error.toString());
        setErrorHappened(1);
        setGettingOBD(0);
      });
  }

  /* Opens the window where the firmware is selected */
  function selectFile() {
    setRenderFileSelection(true);
  }

  /* Closes the window where the firmware is selected */
  function closeSelectFile() {
    setRenderFileSelection(false);
  }
  /* BTConnect
   * Brings up the bluetooth connection window and filters for the esp32
  * TODO: Look for this on iPhone
   */
  function BTConnect() {
    setRenderFileSelection(false);
    setErrorHappened(0);
    navigator.bluetooth.requestDevice({
      filters: [
        {
          namePrefix: "SPDCM",
        },
      ],
      exclusionFilters: excluded,
      optionalServices: [UUID_FOTA_SERVICE]
    })
      .then(device => {
        var temp = dcmQueue.slice(0);
        temp.push(device.name);
        setDcmQueue(temp);
        var dq = deviceQueue.slice(0);
        dq.push(device)
        setDeviceQueue(dq);
      })
  }

  /*Connects to a DCM device
  The parameter device, is the DCM is going to to connect to*/
  async function connectDCM(device) {
    esp32Device = device
    device.gatt.connect()
      .then(server => {
        return server.getPrimaryService(UUID_FOTA_SERVICE)
      })
      .then(service => {
        esp32Service = service;
        setConnected(1);
        setDCMConnected("Connected to " + esp32Service.device.name);
        addLog("Started updating " + esp32Service.device.name + " with file " + file);
      })
      .then(service => {
        return service;
      })
      .then(_ => {

        setTimeout(function () {
          ReadUpdateFile()
        }, 5000);
      })
      .catch(error => {
        if (errorCount < 10) {
          console.log(error);
          setErrorQueue(errorQueue => errorQueue + 1);
          setErrorCount(errorCount => errorCount + 1);
        } else {
          setErrorHappened(1);
          setErrorName('Something went wrong, try again');
          setCooldown(0);
          console.log(error);
        }
      });
  }

  /* 
   * Downloads the firmware based on the hardware version and latest software version and begins sending
    * Returns a call to SendFileOverBluetooth()
   */
  async function ReadUpdateFile() {
    const fileSelected = await Storage.get(file);
    fetch(fileSelected)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (data) {

        updateData = new Uint8Array(data);
        return SendFileOverBluetooth();
      })
      .catch(function (err) {
        if (errorCount < 10) {
          console.log('Something went wrong', err);
          setErrorQueue(errorQueue => errorQueue + 1);
          setErrorCount(errorCount => errorCount + 1);
        } else {
          setErrorHappened(1);
          setErrorName('Something went wrong', err);
          console.log('Something went wrong', err);
        }
      });

  }

  /* 
   * Figures out how large our update binary is, attaches an eventListener to our dataCharacteristic so the Server can tell us when it has finished writing the data to memory
   * Calls SendBufferedData(), which begins a loop of write, wait for ready flag, write, wait for ready flag...
   */
  async function SendFileOverBluetooth() {
    if (!esp32Service) {
      console.log("No esp32 Service");
      return;
    }
    totalSize = updateData.byteLength;
    setDCMConnected("Starting update")

    await timer(5000);
    await esp32Service.getCharacteristic(UUID_STATUS_CHAR)
      .then(characteristic => {
        characteristic.startNotifications();
      })
    //Start FOTA
    await timer(5000);
    await esp32Service.getCharacteristic(UUID_CONTROL_CHAR)
      .then(characteristic => {
        characteristic.writeValue(FOTA_OP_CODE_START);
      })
    await timer(5000);

    setTimeout(function () {
      addLog("Started transfering binary to " + esp32Service.device.name + " with file " + file);
      transfer_binary();
      //
      //
      //ATTENTION: If the update is failing is possible that the 20s wait between the FOTA start and
      //the writing of the packets is not enough. 
      //Increase it here |
      //                 V
      //
      //
    }, 20000);
  }

  /* Gets a chunk of the firmware 
  * The parameter data is the firmware, chunk_size is the size of the chunk, n is the position where is going to take the chunks
  * Returns an array with a chunk of data
  */
  function get_chunk_n(data, chunk_size, n) {
    //Get the chuck
    let start = chunk_size * n
    let end = chunk_size * (n + 1)
    if (data.byteLength <= start) {
      return []
    }
    if (data.byteLength <= end) {
      return data.slice(start)
    }
    return data.slice(start, end);
  }

  /* Adds an id to a chunk of data
  * The parameter data is the firmware, chunk_size is the size of the chunk, n is the position where is going to take the chunks, fragment_id is the id of the fragment
  * Returns an array with a chunk of data with an id
  * */
  function get_fragment(data, chunk_size, n, fragment_id) {
    let result = [fragment_id]
    let chunk = get_chunk_n(data, chunk_size, n)
    for (var i = 0; i < chunk.byteLength; i++) {
      result.push(chunk[i])
    }
    return result
  }
  /* 
   * An ISR attached to the same characteristic that it writes to, this function slices data into characteristic sized chunks and sends them to the Server
   */
  async function transfer_binary() {
    //Function to transfer binary file data over the air
    let binary_sent = false
    let noProblems = true
    setDCMConnected("Transfering binary file")

    let data_len = totalSize
    let total_pkts = (data_len / FRAGMENT_SIZE)
    if (data_len % FRAGMENT_SIZE > 0) {
      total_pkts = total_pkts + 1
    }

    while (!binary_sent) {
      let n = (MAXIMUM_FRAGMENT_ID + 1) * rollover_counter + fragment_id
      let packet_data = new Uint8Array(get_chunk_n(updateData, FRAGMENT_SIZE, n))
      setDCMConnected("Writing packet " + n + " of " + total_pkts + "...")
      if (packet_data.byteLength < FRAGMENT_SIZE) {
        binary_sent = true
      }
      else {
        let fragment = new Uint8Array(get_fragment(updateData, FRAGMENT_SIZE, n, fragment_id))
        await esp32Service.getCharacteristic(UUID_BINARY_STREAM_CHAR)
          .then(characteristic => {
            characteristic.writeValue(fragment)
          })
          .then(_ => {
            fragment_id = fragment_id + 1;
          })
          .catch(error => {
            console.log(error);
            setErrorHappened(1);
            setErrorName('Something went wrong');
            binary_sent = true;
            noProblems = false;
            setErrorQueue(errorQueue => errorQueue + 1);
          });
      }
      await timer(63);
      // In case of rollover, increment counter and reset fragment ID
      if (fragment_id >= MAXIMUM_FRAGMENT_ID + 1) {
        rollover_counter = rollover_counter + 1
        fragment_id = 0
      }
    }


    await timer(5000);
    if (noProblems) {
      await esp32Service.getCharacteristic(UUID_CONTROL_CHAR)
        .then(characteristic => {
          setDCMConnected("DCM updated")
          addLog("Sucessfully updated" + esp32Service.device.name + " with file " + file);

          return characteristic.writeValue(FOTA_OP_CODE_COMMIT);
        })
      await timer(5000);
      setQueue(queue => queue + 1);
    }
  }

  /* Gets the firmware files accessible for the current user logged in and adds them to the dropdown*/
  async function getOptions(i) {
    const result = await Storage.list('DCMupdates/' + i + '/');
    const options = [];
    //options.push({ key: 0, label: '', value: '' });
    for (let i = 1; i < result.results.length; i++) {
      var ivalue = result.results[i].key;
      var ilabel = ivalue;
      ilabel = ilabel.slice(ilabel.indexOf("/") + 1);
      ilabel = ilabel.slice(ilabel.indexOf("/") + 1);
      ilabel = ilabel.slice(0, -4);
      options.push({ key: i, label: ilabel, value: ivalue });
    }
    setDropdown(options);
  }

  /* Creates and updates the HTML code for the read data table */
  async function drawTable() {
    let html = ''
    let tempHtml = ''
    for (let index = 0; index < tableData.length; index++) {
      setCurrentColumnsID(tableData[0][1])
      tempHtml = '<tr >'
      for (let i = 0; i < tableData[index].length; i++) {
        if (activeRows[i] === 1) {
          tempHtml = tempHtml + '<th>' + tableData[index][i] + '</th>'
        }
      }
      tempHtml = tempHtml + '</tr>'
      html = tempHtml + html;
    }

    let headerHtml = ''
    headerHtml = '<tr >'
    if (currentColumnsNames === '') {
      for (let i = 0; i < tableData[0].length; i++) {
        if (activeRows[i] === 1) {
          if (i === 0) {
            headerHtml = headerHtml + '<th>1. TimeStamp</th>'
          } else {
            headerHtml = headerHtml + '<th>' + (i + 1) + '. </th>'
          }
        }
      }
    } else {
      for (let i = 0; i < currentColumnsNames.length; i++) {
        if (activeRows[i] === 1) {
          if (i === 0) {
            headerHtml = headerHtml + '<th>1. TimeStamp</th>'
          } else {
            headerHtml = headerHtml + '<th>' + (i + 1) + '. ' + currentColumnsNames[(i - 1)] + '</th>'
          }
        }
      }
    }
    headerHtml = headerHtml + '</tr>'

    html = ' <tbody>' + headerHtml + html
    html = html + ' </tbody>'
    setTableHtml(html)
    document.getElementById("displayTable").innerHTML = html;
  }

  /* Gets the name of the columns of the read data table 
  * The parameter currentColumsID is the firt column of a mcu string, which contains the customer name, hardware and firmware
  */
  async function getColumnNames(currentColumnsID) {
    const result = await Storage.get("DCMupdates/ColumnNames.json");
    const response = await fetch(result, {
      headers: {
        'Cache-Control': 'no-cache, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    const responseData = await response.json();

    var foundObject = '';
    //currentColumnsID = "snst,8e,1.0.7"
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i].customer === currentColumnsID) {
        foundObject = responseData[i].columns;
        break;
      }
    }
    console.log(foundObject);
    setCurrentColumnsNames(foundObject);
  }

  /* Creates and updates the HTML code for the DCM debug */
  function drawTableDebug() {
    let html = ''
    for (let index = 0; index < tableDataDebug.length; index++) {
      let tempHtml = ''
      tempHtml = '<tr class="noBorder">'
      for (let i = 0; i < tableDataDebug[index].length; i++) {
        tempHtml = tempHtml + '<th>' + tableDataDebug[index][i] + '</th>'
      }
      tempHtml = tempHtml + '</tr>'
      html = tempHtml + html;
    }
    html = ' <tbody>' + html
    html = html + ' </tbody>'
    setTableHtmlDebug(html)
    document.getElementById("displayTableDebug").innerHTML = html;
  }

  /* Gets the videos of the tutorials window, creates the HTML for it and updates it */
  async function getTutorials() {
    try {
      const result = await Storage.get("DCMupdates/TutorialVideos.json");
      const response = await fetch(result, {
        headers: {
          'Cache-Control': 'no-cache, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 0
        }
      });
      const responseData = await response.json();
      const responseDataChunked = responseData.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 3)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])

      let htmlTutorials = '<tbody>'
      for (let index = 0; index < responseDataChunked.length; index++) {
        htmlTutorials = htmlTutorials + '<tr class="noBorder">'
        for (let i = 0; i < responseDataChunked[index].length; i++) {
          htmlTutorials = htmlTutorials + '<iframe src="https://www.youtube.com/embed/' + responseDataChunked[index][i].url.substring(32) + '" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen style="margin: 1%"></iframe>'
        }
        htmlTutorials = htmlTutorials + '</tr>'
      }
      htmlTutorials = htmlTutorials + ' </tbody>'
      document.getElementById("tutorialsTable").innerHTML = htmlTutorials;

    } catch (error) {
      console.log("Error loading tutorials: ", error);
    }
  }

  /* Gets the text for the manual window, which already is in HTML format, and updates it */
  async function getManual() {
    try {
      const result = await Storage.get("DCMupdates/Text.json");
      const response = await fetch(result, {
        headers: {
          'Cache-Control': 'no-cache, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 0
        }
      });
      const responseData = await response.json();

      document.getElementById("manualText").innerHTML = responseData[0].text;

    } catch (error) {
      console.log("Error loading tutorials: ", error);
    }
  }

  /* Creates the dropdown with the firmware available */
  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label>
        <select value={value ? value : "SELECT FILE"} onChange={onChange} className="Button">
          <option defaultValue disabled>SELECT FILE</option>
          {options.map((option) => (
            <option key={option.key} value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  }

  /* Updates a hook so it contains the firmware selected */
  const handleChange = (event) => {
    setFile(event.target.value);
  }

  /* Redirects to Outlook so the user can send an email to SP */
  function mailTo() {
    window.location.href = "mailto:customerservice@stealth-power.com";
  }

  if (isAuthenticated) {
    return (
      <div className="App">


        {renderTable === true &&
          <div >
            <div className="transparentGrey" ></div>
            <div className="displayTable" >
              <div className="br"></div>
              {autoRead === true &&
                <span>
                  <button className="Button" onClick={readerSwitch}>
                    {read}
                  </button>
                </span>
              }
              {autoRead === false &&
                <span>
                  <button className="Button" disabled>
                    {read}
                  </button>
                </span>
              }
              <span className="blank2"></span>
              <span>
                {tableData.length > 0 && excelReady === true &&
                  <button className="Button" onClick={clearTable}>
                    CLEAR TABLE
                  </button>
                }
                {(tableData.length === 0 || excelReady === false) &&
                  <button className="Button" disabled>
                    CLEAR TABLE
                  </button>
                }
              </span>
              <div className="blank2"></div>
              <div>
                <span>
                  {tableData.length > 0 && excelReady === true &&
                    <DownloadTableExcel
                      filename="DCM data"
                      sheet="DCM data"
                      currentTableRef={tableRef.current}
                    >
                      <button className="Button"> EXPORT EXCEL </button>
                    </DownloadTableExcel>
                  }
                  {(tableData.length === 0 || excelReady === false) &&
                    <button className="Button" disabled> EXPORT EXCEL </button>
                  }
                </span>
                <span className="blank2"></span>
                {closeAble === true &&
                  <span>
                    <button className="Button" onClick={switchWindow}>
                      CLOSE
                    </button>
                  </span>
                }
                {closeAble === false &&
                  <span>
                    <button className="Button" disabled>
                      CLOSE
                    </button>
                  </span>
                }
              </div>
              <div className="blank2"></div>
              {currentlyReadingDCM !== "SPDCM" &&
                <div>
                  <b>Reading data from: {currentlyReadingDCM}&ensp;Firmware: {currentlyReadingDCMVersion}</b>
                  <div className="blank2"></div>
                  <b>OBD DATA: Is Parked: {isParked}&ensp;Engine On: {isEngineOn}&ensp;Is Moving: {isMoving}</b>
                </div>
              }
              {errorNameData !== "" &&
                <div>
                  <font color="#FF0000">{errorNameData}</font>
                  <div className="blank2"></div>
                </div>
              }
              <fieldset id="rowSelection" className='rowSelection'>
              </fieldset>
              <table id="displayTable" ref={tableRef} className="br2"></table>
            </div>
          </div>
        }



        {
          renderTableDebug === true &&
          <div >
            <div className="transparentGrey" ></div>
            <div className="displayTable" >
              <div className="br">
                {autoReadDebug === true &&
                  <span>
                    <button className="Button" onClick={readerSwitchDebug}>
                      {readDebug}
                    </button>
                  </span>
                }
                {autoReadDebug === false &&
                  <span>
                    <button className="Button" disabled>
                      {readDebug}
                    </button>
                  </span>
                }
                <span className="blank2"></span>
                <span>
                  {tableDataDebug.length > 0 && excelReadyDebug === true &&
                    <button className="Button" onClick={clearTableDebug}>
                      CLEAR TABLE
                    </button>
                  }
                  {(tableDataDebug.length === 0 || excelReadyDebug === false) &&
                    <button className="Button" onClick={clearTableDebug} disabled>
                      CLEAR TABLE
                    </button>
                  }
                </span>
              </div>
              <div className="blank2"></div>
              <div>
                <span>
                  {tableDataDebug.length > 0 && excelReadyDebug === true &&
                    <DownloadTableExcel
                      filename="DCM debug"
                      sheet="DCM debug"
                      currentTableRef={tableRefDebug.current}
                    >
                      <button className="Button"> EXPORT EXCEL </button>
                    </DownloadTableExcel>
                  }
                  {(tableDataDebug.length === 0 || excelReadyDebug === false) &&
                    <button className="Button" disabled> EXPORT EXCEL </button>
                  }
                </span>
                <span className="blank2"></span>
                {closeAbleDebug === true &&
                  <span>
                    <button className="Button" onClick={switchWindowDebug}>
                      CLOSE
                    </button>
                  </span>
                }
                {closeAbleDebug === false &&
                  <span>
                    <button className="Button" disabled>
                      CLOSE
                    </button>
                  </span>
                }
              </div>
              <div className="blank2"></div>
              {currentlyReadingDCMDebug !== "SPDCM" &&
                <div>
                  <b>{currentlyReadingDCMDebug}</b>
                  <div className="blank2"></div>
                </div>
              }
              {errorNameDebug !== "" &&
                <div>
                  <font color="#FF0000">{errorNameDebug}</font>
                  <div className="blank2"></div>
                </div>
              }
              <table id="displayTableDebug" ref={tableRefDebug} className="br"></table>
            </div>
          </div>
        }


        {renderFileSelection === true &&
          <div >
            <div className="transparentGrey" ></div>
            <div className="displayFileSelection" >
              <div className="br">
                <span>
                  <Dropdown
                    label=""
                    options={dropdown}
                    value={file}
                    onChange={handleChange}
                  />
                </span>
              </div>
              <div className="br">
                {file === '' &&
                  <span>
                    <button className="Button" disabled>
                      ACCEPT
                    </button>
                  </span>
                }
                {file !== '' &&
                  <span>
                    <button className="Button" onClick={BTConnect}>
                      ACCEPT
                    </button>
                  </span>
                }
                <span className="blank2"></span>
                <span>
                  <button className="Button" onClick={closeSelectFile}>
                    CANCEL
                  </button>
                </span>
              </div>
            </div>
          </div>
        }

        {renderManualWindow === true &&
          <div >
            <div className="transparentGrey" ></div>
            <div className="displayManualWindow" >
              <p className="manualText" id="manualText"></p >
              <span>
                <button className="Button" onClick={switchManualWindow}>
                  CLOSE
                </button>
              </span>
              <div className="br">
              </div>
            </div >
          </div >
        }


        {
          renderTutorials === true &&
          <div >
            <div className="transparentGrey" ></div>
            <div className="displayTutorialWindow" >
              <table id="tutorialsTable" className="br"></table>
              <span>
                <button className="Button" onClick={switchTutorials}>
                  CLOSE
                </button>
              </span>
              <div className="br">
              </div>
            </div>
          </div>
        }

        <div className={darkmode ? "App-header-Dark" : "App-header"}>

          <div className="Row">

            <img className="LogoImg" src={darkmode ? darkLogo : logo} alt="logo" width={darkmode ? '25%' : '20%'} />
            <div className="break"></div>
            <div className="LogOutCorner">
              {user.name}{' '}

              <div className="break"></div>
              <button className="LogOutButton" onClick={() => logout({ returnTo: window.location.origin })}>
                LOG OUT
              </button>
            </div>
          </div>

          <div className="br"></div>
          <div className="CentralButtons">
            {connected === 0 && errorHappened === 0 &&
              <div>
                < h1 >DCM CONTROLLER </h1>
              </div>
            }
            {connected === 1 && errorHappened === 0 &&
              < div >
                <h1>{DCMconnected}</h1>
              </div>
            }
            {errorHappened === 1 &&
              <div>
                <h1>{DCMconnected}</h1>
                <h3><font color="#FF0000">{errorName}</font></h3>
              </div>
            }
            <table className="ButtonsTable">
              <tbody>
                <tr className="noBorder">
                  {(gettingVersion === 1 || reading === 1 || cooldown === 1 || gettingOBD === 1) &&
                    <th>
                      <button className="Button" disabled>
                        UPDATE DCM
                      </button>
                    </th>
                  }
                  {(gettingVersion === 0 && reading === 0 && cooldown === 0 && gettingOBD === 0) &&
                    <th>
                      <button className="Button" onClick={selectFile}>
                        UPDATE DCM
                      </button>
                    </th>
                  }
                  {(updating === 1 || gettingVersion === 1 || gettingOBD === 1) &&
                    <th>
                      <button className="Button" disabled>
                        READ DATA
                      </button>
                    </th>
                  }
                  {(updating === 0 && gettingVersion === 0 && gettingOBD === 0) &&
                    <th>
                      <button className="Button" onClick={switchWindow}>
                        READ DATA
                      </button>
                    </th>
                  }
                </tr>


                <tr className="noBorder">
                  {(updating === 1 || reading === 1 || gettingOBD === 1) &&
                    <th>
                      <button className="Button" disabled>
                        CHECK VERSION
                      </button>
                    </th>
                  }
                  {(updating === 0 && reading === 0 && gettingOBD === 0) &&
                    <th>
                      <button className="Button" onClick={getVersion}>
                        CHECK VERSION
                      </button>
                    </th>
                  }
                  {(updating === 1 || reading === 1 || gettingVersion === 1) &&
                    <th>
                      <button className="Button" disabled>
                        OBD DATA
                      </button>
                    </th>
                  }
                  {(updating === 0 && reading === 0 && gettingVersion === 0) &&
                    <th>
                      <button className="Button" onClick={getObdData}>
                        OBD DATA
                      </button>
                    </th>
                  }
                </tr>

                <tr className="noBorder">
                  <th>
                    <button className="Button" onClick={switchManualWindow}>
                      MANUAL
                    </button>
                  </th>

                  <th>
                    <button className="Button" onClick={switchTutorials}>
                      VIDEOS
                    </button>
                  </th>

                </tr>

                <tr className="noBorder">
                  {(updating === 1 || gettingVersion === 1 || gettingOBD === 1 || (user.name !== "mcasanova@stealth-power.com" && user.name !== "grodriguez@stealth-power.com")) &&
                    <th>
                      <button className="Button" disabled>
                        DEBUG DCM
                      </button>
                    </th>
                  }
                  {updating === 0 && gettingVersion === 0 && gettingOBD === 0 && (user.name === "mcasanova@stealth-power.com" || user.name === "grodriguez@stealth-power.com") &&
                    <th>
                      <button className="Button" onClick={switchWindowDebug} >
                        DEBUG DCM
                      </button>
                    </th>
                  }
                </tr>

              </tbody>
            </table>


            <div>
              {dcmQueue.length > 0 &&
                <h5><u> Current:</u><br />{dcmQueue[0]}<br /><u>Next:</u><div id="list"></div></h5>
              }
            </div>
          </div>

          <div className="ContactCorner">
            <div className="break"></div>
            <button className={darkmode ? "ContactButtonDark" : "ContactButtonLight"} onClick={mailTo}>
              CONTACT US
            </button>
          </div>
        </div>

      </div >
    );
  } else {
    return (
      <div className="App">
        <div className={darkmode ? "App-header-Dark-Login" : "App-header-Login"}>
          <img src={darkmode ? darkLogo : logo} alt="logo" width={darkmode ? '61%' : '50%'} />
          <div>
            < h2 > DCM CONTROLLER</h2>
          </div>
          <br />
          <button className="Button" onClick={loginWithRedirect}>LOG IN</button>
        </div>
      </div >
    );
  }
}

export default App;